<template>
  <div>
    <b-container fluid class="p-0">
      <div class="content-initial">
        <div class="content-leadad">
          <Alerta :text="getMessage" :bgAlert="getBgMessage" />
          <Load :loading="getLoading"/>
          <Slidebar />
          <slot/>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import Slidebar from "@/components/LeadAd/Slidebar.vue";
import Alerta from '../BaseAlert.vue';
import Load from '../BaseLoad.vue';
import {  mapGetters } from 'vuex'

export default {
  name:'Content',
  components:{
    Load,
    Slidebar,
    Alerta
  },
  computed: {
    ...mapGetters(['getMessage', 'getBgMessage', 'getLoading'])
  }
};
</script>

<style >

.content-initial {
  display: flex;
  background: #fdfcff;
}

.content-leadad {
  width: 100%;
}

.content {
  padding-left: 132px;
  padding-top: 35px;
}

</style>