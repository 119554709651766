<template>
  <Slidebar :links="links" />
</template>
<script>
import Slidebar from "../BaseSlidebar.vue";

export default {
  name: "Slide-bar",
  components: {
    Slidebar,
  },
  data: () => ({
    links: [
      {
        to: "/lead-ads",
        icon: require("@/assets/icons/window.svg"),
        title: "Fanpages",
        name: "Lista-de-fanpages Fanpage",
      },
      {
        to: "/lead-ads/leadgen/",
        icon: require("@/assets/icons/relogio.svg"),
        title: "Histórico",
        name: "Historic-integration",
      },
    ],
  }),
};
</script>