<template>
  <div class="row col-12 p-0">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
          <div id="retangle-lead-add"></div>
          <h1 class="m-0">LeadAds</h1>
        </div>
      <slot />
    </div>
    <div class="col-11 content-link-page">
        <slot name="link"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header',

};
</script>

    
<style lang="css">
:root {
  --color-title: #333333;
  --wight-title: 800;
  --background-color-green: #00e4a0;
  --background-color-white-light: #f5f2fc;
  --default-width-column: 7.69;
}

.content-header {
  margin-top: 60.25px;
}

.content-link-page {
  margin-top: 24.51px;
}

.link-page {
  font-style: normal;
  font-weight: normal;
  text-decoration: none !important;
  cursor: pointer;
  color: #8c8a97;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.link-page:hover {
  color: #8c8a97;
}

h1 {
  color: var(--color-title);
  font-weight: var(--wight-title);
  line-height: 29px;
  font-size: 24px;
}

#retangle-lead-add {
  border-radius: 5px;
  width: 20px;
  height: 7px;
  background-color: var(--background-color-green);
  margin-right: 15.2px;
}

.text-item{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #8C8A97;
}
</style>