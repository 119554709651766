<template>
  <footer class="row col-12 p-0">
    <div class="col-12 mb-5 mt-3">
      <b-pagination
        prevText="Anterior"
        nextText="Próximo"
        v-model="value"
        :total-rows="rows"
        :perPage="perPage"
        @change="$emit('change', $event)"
      ></b-pagination>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  props: {
    rows: {
      type: Number,
    },
    perPage: {
      type: Number,
    },
  },
  data: () => ({
    currentPage: 0,
    value: 1,
  }),
};
</script>
<style >
footer div {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.page-item.disabled .page-link {
  border: 0px;
}
.page-link {
  color: #000000;
  background-color: #fff;
  border: 0;
}
.page-item .page-link  {
  border-radius: 5px;
}

.page-item.active .page-link {
  background: #f5f2fc !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
</style>
    